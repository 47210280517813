<template>
  <div>
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-body row">
          <div class="col-sm-6">
            <div class="card-header">
              <h5 class="card-title">Salesboard</h5>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="pull-right">
              <div class="btn-group">
                <p-button type="primary" round outline @click="resetTable()">Reset</p-button>
                <p-button
                  type="primary"
                  round
                  outline
                  @click="handleOpenDisplayCols()"
                >Display Columns</p-button>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body row">
          <div class="col-sm-12 mt-2">
            <div>
              <vue-good-table
                :theme="tableTheme"
                ref="salesboardGrid"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :columns="columns"
                :rows="rows"
                :pagination-options="{
                enabled: true,
                mode: 'records',

                perPage: serverParams.perPage,      // set programmatially
                setCurrentPage: serverParams.page,   // set programmatically

                position: 'bottom',
                perPageDropdown: [10, 30, 100],
                dropdownAllowAll: true,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                }"
                :sort-options="{
                enabled: true,
                initialSortBy: serverParams.sort
                //initialSortBy: {field: 'company_name', type: 'desc'} // set programmatically
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <p-button
                      type="success"
                      size="sm"
                      icon
                      @click="handleEdit(props.$index, props.row)"
                    >
                      <i class="fa fa-edit"></i>
                    </p-button>
                  </span>
                  <span v-if="props.column.field == 'company_name'">
                    <router-link :to="{ name: 'Client', params: { id: props.row.client_guid }}">{{props.formattedRow[props.column.field]}}</router-link>
                  </span>
                  <span v-else>{{props.formattedRow[props.column.field]}}</span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <!-- Add column selection Modal -->
    <div>
      <modal :show.sync="modals.displayColsModal" headerClasses="justify-content-center">
        <h5 slot="header" class="title title-up">Choose columns to display</h5>
        <div>
          <vue-good-table :columns="diplayColsColumns" :rows="displayColumnsSettings">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'display'">
                <p-button
                  type="info"
                  size="sm"
                  v-if="props.row.hidden"
                  icon
                  @click="handleDisplayCol(props.$index, props.row)"
                >
                   <i class="fa fa-square-o fa-2x"></i>
                </p-button>
                <p-button
                  type="info"
                  size="sm"
                  v-else
                  icon
                  @click="handleHideCol(props.$index, props.row)"
                >

                  <i class="fa fa-check-square-o fa-3x"></i>
                </p-button>
              </span>
              <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </template>
          </vue-good-table>
        </div>

        <div class="form-group">
            <div class="col-sm-12">
              <div class="pull-right">
                <div class="col-md-12">
                  <p-button type="default" @click="handlColDisplayCancel()">Cancel</p-button>
                  <p-button type="primary" @click="applyColDisplaySelects()">Apply</p-button>
                </div>
              </div>
            </div>
          </div>
      </modal>
    </div>

    <!--  -->
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { Card, Modal } from "src/components/UIComponents";
import * as CONSTS from "../../../consts/constants";
var numeral = require("numeral");

export default {
  components: { Modal },
  computed: {
    ...mapGetters([
      "userSelectedProduction",
      "userSelectedProductionInt",
      "userSelectedProductionCcy",
      "gridStates",
      "tableTheme"
    ]),
    productionSelected() {
      return this.$store.getters.userSelectedProduction;
    }
  },
  mounted() {
    // https://github.com/xaksis/vue-good-table/issues/410
    this.subPublications = [];

    this.$set(this.columns[1], "filterOptions", {
      enabled: true,
      filterDropdownItems: this.subPublications
    });

    // https://github.com/xaksis/vue-good-table/issues/275
    // this needs to be done properly once this enhancement is made

    // this.$refs.salesboardGrid.columnFilters = this.serverParams.columnFilters
    // this.$refs.salesboardGrid.filterRows(this.serverParams.columnFilters, true)

    this.updateTableFilters();


    // intiate the displayColumnsSettings


    this.setTableHiddenCols();

    // console.log('mounted: gridState', this.$refs.salesboardGrid.columnFilters )
  },
  created() {
    // let gridState = this.$store.getters.getGridState(this.gridName)
    // console.log('mounted: gridState', this.gridStates)

    let gridState = this.gridStates[this.gridName];

    if (gridState) {
      this.serverParams = gridState;
    }
    this.loadData();
  },
  watch: {
    productionSelected(userSelectedProduction, oldValue) {
      // console.log('Salesbiard productionSelected')
      // userSelectedProduction has changed (loaded)
      this.loadData();
    }
  },

  data() {
    return {
      subPublications: [],

      modals: {
        displayColsModal: false
      },

      gridName: "salesboardGrid",
      serverParams: {
        gridName: "salesboardGrid",
        hiddenCols: [],
        columnFilters: {},
        sort: {
          field: "date_booked",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },

      displayColumnsSettings:[], // the array that is displayed in the modal - initiated from the stored server params

      // display cols table
      diplayColsColumns: [
        {
          label: "Display",
          field: "display"
        },
        {
          field: "label",
          label: "Column"
        },
        {
          field: "hidden",
          label: "hidden"
        }
      ],

      columns: [
        {
          label: "Edit",
          field: "actions"
        },

        {
          field: "subproduction_id",
          label: "Publication",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatSubPublication,
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
            filterDropdownItems: this.subPublications
            //  filterDropdownItems: CONSTS.strings.ad_type
          }
        },
        {
          field: "company_name",
          label: "Client",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter"
            // filterValue: this.test
          }
        },
        {
          field: "full_name",
          label: "Sales Person",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter"
          }
        },
        {
          field: "date_booked",
          label: "Booking Date",
          formatFn: this.formatDate,
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter"
          }
        },
        {
          field: "ad_type",
          label: "Booking Type",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatType,
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
            filterDropdownItems: CONSTS.strings.ad_type
          }
        },
        {
          field: "package_name",
          label: "Package",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter"
          }
        },
        {
          field: "vendortxcode",
          label: "Reference",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter"
          }
        },
        {
          field: "price",
          label: "Price",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatPrice,
          type: "decimal",
          filterOptions: {
            enabled: true,
            placeholder: "Filter"
          }
        },
        {
          field: "exportedtosage",
          label: "Exported to Accounts",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatExportToAccounts,
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
            filterDropdownItems: CONSTS.strings.exportedtosage
          }
        },
        {
          field: "status",
          label: "Booking Status",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatStatus,
          type: "number",
          filterOptions: {
            enabled: true,
            filterDropdownItems: CONSTS.strings.status
          }
        },
        {
          field: "invoicenumber",
          label: "Invoice Number",
          tdClass: "tablecell",
          thClass: "tablecell",
          //formatFn: this.formatBillingStatus,
          // type: "number",
          filterOptions: {
            enabled: true
          }
        },
        {
          field: "billing_status",
          label: "Billing Status",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatBillingStatus,
          type: "number",
          filterOptions: {
            enabled: true,
            filterDropdownItems: CONSTS.strings.billing_status
          }
        },
        {
          field: "boxed",
          label: "Boxed",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatValid,
          type: "number",
          filterOptions: {
            enabled: true,
            filterDropdownItems: CONSTS.strings.no_yes
          }
        },
        {
          field: "video",
          label: "Video",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatValid,
          type: "number",
          filterOptions: {
            enabled: true,
            filterDropdownItems: CONSTS.strings.no_yes
          }
        },
        {
          field: "logo",
          label: "Logo",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatValid,
          type: "number",
          filterOptions: {
            enabled: true,
            filterDropdownItems: CONSTS.strings.no_yes
          }
        },
        {
          field: "logo_status",
          label: "Logo Status",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatLogoStatus,
          type: "number",
          filterOptions: {
            enabled: true,
            filterDropdownItems: CONSTS.strings.logo_status
          }
        },
        {
          field: "ad_status",
          label: "Artwork Status",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatArtworkStatus,
          type: "number",
          filterOptions: {
            enabled: true,
            filterDropdownItems: CONSTS.strings.ad_status
          }
        },
        {
          field: "product_shot",
          label: "Product Shot",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatValid,
          type: "number",
          filterOptions: {
            enabled: true,
            filterDropdownItems: CONSTS.strings.no_yes
          }
        },
        {
          field: "product_sponsor",
          label: "Product Sponsor",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatValid,
          type: "number",
          filterOptions: {
            enabled: true,
            filterDropdownItems: CONSTS.strings.no_yes
          }
        },
        {
          field: "ad_size",
          label: "Size",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatAdSize,
          type: "number",
          filterOptions: {
            enabled: true,
            filterDropdownItems: CONSTS.strings.ad_size
          }
        }
      ],
      rows: []
    };
  },
  methods: {
    //

    handleOpenDisplayCols() {
      this.modals.displayColsModal = true;
    },
    formatValid: function(value) {
      let obj = this.searchArray(CONSTS.strings.no_yes, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    formatLogoStatus: function(value) {
      let obj = this.searchArray(CONSTS.strings.logo_status, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    formatArtworkStatus: function(value) {
      let obj = this.searchArray(CONSTS.strings.ad_status, value, "value");
      if (obj) {
        return obj.text;
      }
    },

    formatAdSize: function(value) {
      let obj = this.searchArray(CONSTS.strings.ad_size, value, "value");
      if (obj) {
        return obj.text;
      }
    },


    // column display funcs

    // setColumnsHiddenValue() {
    //   for (let i = 0; i < this.serverParams.hiddenCols.length; i++) {
    //     this.$set(this.columns[i], "hidden", true);
    //   }
    // },


    handlColDisplayCancel() {
      this.modals.displayColsModal = false;
    },
    applyColDisplaySelects(){

      // this.serverParams.hiddenCols holds the persisted array of col numbers
      //  this.displayColumnsSettings is the in mem list that is dsiplayed in the dialog

      // reset
      this.serverParams.hiddenCols = []

      // set the serverParams.hiddenCols from displayColumnsSettings
      for (let i = 0; i < this.displayColumnsSettings.length; i++) {

        if (this.displayColumnsSettings[i].hidden === true){
          this.serverParams.hiddenCols.push(i)
          this.$set(this.columns[i], "hidden", true);
        }
      }

      this.$store.dispatch("setGridState", this.serverParams);
      this.modals.displayColsModal = false;
      // console.log(this.serverParams.hiddenCols)
    },

    handleDisplayCol (index, row) {

      // console.log('handleDisplayCol')

      // this.columns[row.originalIndex].hidden = false
      this.displayColumnsSettings[row.originalIndex].hidden = false

      // console.log(this.displayColumnsSettings)

    },
    handleHideCol (index, row) {

      // console.log(row)
      // console.log( row.originalIndex)

      // console.log('handleHideCol')

      // this.columns[row.originalIndex].hidden = true
      this.displayColumnsSettings[row.originalIndex].hidden = true
    },

    setTableHiddenCols() {

      this.displayColumnsSettings = []

      for (let i = 0; i < this.columns.length; i++ ){

        let obj = {}

        obj.hidden = false
        obj.label = this.columns[i].label
        this.displayColumnsSettings.push(obj)

        this.$set(this.columns[i], "hidden", false);
      }

      // console.log(this.displayColumnsSettings)
      // console.log(this.serverParams.hiddenCols)

      for (let i = 0; i < this.serverParams.hiddenCols.length; i++) {
        this.$set(this.columns[i], "hidden", true);
        this.displayColumnsSettings[i].hidden = true
      }
    },

    // column display funcs - end

    updateTableFilters() {
      for (let i = 0; i < this.columns.length; i++) {
        const col = this.columns[i];
        if (typeof col.filterOptions !== "undefined") {
          let filterVal = {};

          if (
            (filterVal = this.serverParams.columnFilters[this.columns[i].field])
          ) {
            let filter = this.columns[i].filterOptions;
            filter.filterValue = filterVal;
            // seems bonkers having to do this
            let filterString = JSON.stringify(filter);
            let filterObj = JSON.parse(filterString);

            this.$set(this.columns[i], "filterOptions", filterObj);
            // this.$set(this.columns[i], 'filterOptions', { enabled: true, filterValue: filterVal })
          }
        }
      }
    },

    resetTable: function() {
      this.$refs["salesboardGrid"].reset();
      this.serverParams.columnFilters = [];
      this.$store.dispatch("setGridState", this.serverParams);
      this.serverParams.hiddenCols = []
      this.setTableHiddenCols()
    },

    // table events
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      this.$store.dispatch("setGridState", this.serverParams);
    },

    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage;
      this.$store.dispatch("setGridState", this.serverParams);
    },

    onSortChange(params) {
      if (params.columnIndex){

      let newSort = {
        type: params.sortType,
        field: this.columns[params.columnIndex].field
      };

      this.serverParams.sort = newSort;
      this.$store.dispatch("setGridState", this.serverParams);
      }
    },

    onColumnFilter(params) {
      this.serverParams.columnFilters = params.columnFilters;
      this.$store.dispatch("setGridState", this.serverParams);
    },

    // end table events

    searchArray: function(array, key, prop) {
      // Optional, but fallback to key['name'] if not selected
      prop = typeof prop === "undefined" ? "name" : prop;

      // console.log(array, key, prop)

      for (var i = 0; i < array.length; i++) {
        if (array[i][prop] === key) {
          return array[i];
        }
      }
      return null;
    },

    formatDate(value) {
      return this.$moment(value).format("DD-MM-YYYY");
    },

    formatSubPublication: function(value) {
      let obj = this.searchArray(this.subPublications, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    formatType: function(value) {
      let obj = this.searchArray(CONSTS.strings.ad_type, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    formatPrice: function(value) {
      let financialFormat = new String("0,0.00");
      return (
        this.userSelectedProductionCcy + numeral(value).format(financialFormat)
      );
    },
    formatExportToAccounts: function(value) {
      let obj = this.searchArray(CONSTS.strings.exportedtosage, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    formatStatus: function(value) {
      let obj = this.searchArray(CONSTS.strings.status, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    formatBillingStatus: function(value) {
      let obj = this.searchArray(CONSTS.strings.billing_status, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    formatHeat: function(value) {
      let obj = this.searchArray(CONSTS.strings.heat, value, "value");
      if (obj) {
        return obj.text;
      }
    },

    getSubPublications: async function() {
      // return new Promise(resolve => {

      //  console.log('getSubPublications start')

      let vm = this;
      await this.$http
        .get("common/production/" + this.userSelectedProductionInt + "/sub")
        .then(response => {
          // console.log('getSubPublications returned')
          vm.subPublications = response.data;

          vm.$set(this.columns[1], "filterOptions", {
            enabled: true,
            filterDropdownItems: vm.subPublications
          });
        })
        .catch(error => {
          // console.log(error)
          vm.$notify({
            message:
              "There was a problem loading the publications. Please try again.",
            type: "warning"
          });
        });

      // console.log('getSubPublications end')

      //  });
    },

    loadData() {
      this.getSubPublications();

      this.$http
        .get("sales/production/bookings/" + this.userSelectedProduction)

        .then(response => {
          this.rows = response.data;
        })
        .catch(error => {
          // console.log(error)
          this.$notify({
            message:
              "There was a problem loading the salesboard. Please try again.",
            type: "warning"
          });
        });
    },
    handleEdit(index, row) {
      // https://github.com/xaksis/vue-good-table/issues/142
      // access the filtered table data
      // console.log( "myTable", this.$refs.myTable.filteredRows[0].children )

      //  console.log( "salesboardGrid Filters", this.$refs.salesboardGrid.columnFilters)
      // console.log( "salesboardGrid", this.$refs.salesboardGrid.currentPage)
      // console.log( "salesboardGrid", this.$refs.salesboardGrid.sortColumn)
      // console.log( "salesboardGrid", this.$refs.salesboardGrid.sortType)

      // console.log(  this.serverParams )
      // console.log( 'table obj:', this.$refs.salesboardGrid )

      // this.$refs.salesboardGrid.$children[1].updateFiltersImmediately({field: 'company_name'}, 'logo');

      try {
        // this.$router.push({ name: 'Booking', params: { id: 0, client_id: this.model.client_id, client_guid: this.id, production_id: this.userSelectedProductionInt, company_name: this.model.company_name, bookingmode: CONSTS.BOOKING }})

        this.$router.push({
          name: "Booking",
          params: { id: row.guid, bookingmode: CONSTS.BOOKING }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
<style lang="scss">
.tablecell {
  font-size: 14px;
}
</style>
